import './App.css';

import { Bar, Line } from 'react-chartjs-2';
import { useEffect, useMemo, useState } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import moment from 'moment';
import 'chartjs-adapter-moment';
import { useRef } from 'react';
import { Autocomplete, Box, Slider, TextField } from '@mui/material';
import Moment from 'react-moment';
import style from './App.module.css'



function App() {
  const dadosCoriza = useRef([]);
  const dadosCovid = useRef([]);
  const dadosDiarreia = useRef([]);
  const dadosDorDeCabeca = useRef([]);
  const dadosDorDeGarganta = useRef([]);
  const dadosFaltaDeAr = useRef([]);
  const dadosFebre = useRef([]);
  const dadosGripe = useRef([]);
  const dadosResfriado = useRef([]);
  const dadosTosse = useRef([]);
  const dadosVomito = useRef([]);
  const dadosGripal = useRef([]);

  const dadosCoriza2023 = useRef([]);
  const dadosCovid2023 = useRef([]);
  const dadosDiarreia2023 = useRef([]);
  const dadosDorDeCabeca2023 = useRef([]);
  const dadosDorDeGarganta2023 = useRef([]);
  const dadosFaltaDeAr2023 = useRef([]);
  const dadosFebre2023 = useRef([]);
  const dadosGripe2023 = useRef([]);
  const dadosResfriado2023 = useRef([]);
  const dadosTosse2023 = useRef([]);
  const dadosVomito2023= useRef([]);
  const dadosGripal2023 = useRef([]);

  var cod_estados = [
    {"UF":"AC",
    "COD": 12, "NOME":"Acre"},
    {"UF":"AL",
    "COD": 27, "NOME":"Alagoas"},
    {"UF":"AP",
    "COD": 16, "NOME":"Amapá"},
    {"UF":"AM",
    "COD": 13, "NOME":"Amazonas"},
    {"UF":"BA",
    "COD": 29, "NOME":"Bahia"},
    {"UF":"CE",
    "COD": 23, "NOME":"Ceará"},
    {"UF":"DF",
    "COD": 53, "NOME":"Distrito Federal"},
    {"UF":"ES",
    "COD": 32, "NOME":"Espírito Santo"},
    {"UF":"GO",
    "COD": 52, "NOME":"Goiás"},
    {"UF":"MA",
    "COD": 21, "NOME":"Maranhão"},
    {"UF":"MT",
    "COD": 51, "NOME":"Mato Grosso"},
    {"UF":"MS",
    "COD": 50, "NOME":"Mato Grosso do Sul"},
    {"UF":"MG",
    "COD": 31, "NOME":"Minas Gerais"},
    {"UF":"PA",
    "COD": 15, "NOME":"Pará"},
    {"UF":"PB",
    "COD": 25, "NOME":"Paraíba"},
    {"UF":"PR",
    "COD": 41, "NOME":"Paraná"},
    {"UF":"PE",
    "COD": 26, "NOME":"Pernambuco"},
    {"UF":"PI",
    "COD": 22, "NOME":"Piauí"},
    {"UF":"RJ",
    "COD": 33, "NOME":"Rio de Janeiro"},
    {"UF":"RN",
    "COD": 24, "NOME":"Rio Grande do Norte"},
    {"UF":"RS",
    "COD": 43, "NOME":"Rio Grande do Sul"},
    {"UF":"RO",
    "COD": 11,"NOME":"Rondônia"},
    {"UF":"RR",
    "COD": 14,"NOME":"Roraima"},
    {"UF":"SC",
    "COD": 42,"NOME":"Santa Catarina"},        
    {"UF":"SP",
    "COD": 35,"NOME":"São Paulo"},
    {"UF":"SE",
    "COD": 28,"NOME":"Sergipe"},
    {"UF":"TO",
    "COD": 17,"NOME":"Tocantins"},
];

  const [ocultaCoriza, setOcultaCoriza] = useState(false);
  const [ocultaCovid, setOcultaCovid] = useState(false);
  const [ocultaDiarreia, setOcultaDiarreia] = useState(false);
  const [ocultaDorDeCabeca, setOcultaDorDeCabeca] = useState(false);
  const [ocultaDorDeGarganta, setOcultaDorDeGarganta] = useState(false);
  const [ocultaFaltaDeAr, setOcultaFaltaDeAr] = useState(false);
  const [ocultaFebre, setOcultaFebre] = useState(false);
  const [ocultaGripe, setOcultaGripe] = useState(false);
  const [ocultaResfriado, setOcultaResfriado] = useState(false);
  const [ocultaTosse, setOcultaTosse] = useState(false);
  const [ocultaVomito, setOcultaVomito] = useState(false);
  const [ocultaGripal, setOcultaGripal] = useState(false);

  const [exibeBotoes, setExibeBotoes] = useState(false);

  const atualizandoGrafico = useRef(false);
  const contadorReescala = useRef(0);

  const chartRef = useRef(null);

  const legenda = [];

  const getDadosGrafico = (
    dados_total_atendimentos_gripais, dados, fullScreen, 
    titulo_atendimento_gripais, titulo, borderColor2, 
    backgroundColor2) => {
    return {
        legenda,
        datasets: [
            {
                fill: true,
                label: titulo_atendimento_gripais,
                data: dados_total_atendimentos_gripais,
                borderColor: corAtendimentosGripais,
                backgroundColor: corBackgroundAtendimentosGripais,
                borderWidth: fullScreen ? 2 : 1,
                pointBorderWidth: fullScreen ? 2 : 1.5,
                pointRadius: fullScreen ? 1.5 : 1.3,
                yAxisID: 'y',
            },
            {
                fill: true,
                label: titulo,
                data: dados,
                borderColor: borderColor2,
                backgroundColor: backgroundColor2,
                borderWidth: fullScreen ? 2 : 1,
                pointBorderWidth: fullScreen ? 2 : 1.5,
                pointRadius: fullScreen ? 1.5 : 1.3,
                yAxisID: 'y2',
            },
            
        ],

    };
  }

  const getDadosGraficoTodos = (titulo_atendimento_gripais, dados_total_atendimentos_gripais, titulo, dados, titulo2, dados2, titulo3, dados3, titulo4, dados4, titulo5, dados5, titulo6, dados6, titulo7, dados7, titulo8, dados8, titulo9, dados9, titulo10, dados10, titulo11, dados11, fullScreen) => {
    return {
        legenda,
        datasets: [
            {
                cubicInterpolationMode: 'monotone',
                fill: true,
                label: titulo_atendimento_gripais,
                data: dados_total_atendimentos_gripais,
                borderColor: /*corAtendimentosGripais*/'red',
                backgroundColor: /*corBackgroundAtendimentosGripais*/ 'red',
                borderWidth: fullScreen ? 6 : 3,
                pointBorderWidth: fullScreen ? 2 : 1.5,
                pointRadius: 0,
                yAxisID: 'y',
                hidden:false,
            },
            {
                cubicInterpolationMode: 'monotone',
                fill: true,
                label: titulo,
                data: dados,
                borderColor: borderColor2,
                backgroundColor: backgroundColor2,
                borderWidth: fullScreen ? 2 : 1,
                pointBorderWidth: fullScreen ? 2 : 1.5,
                pointRadius: 0,
                 yAxisID: 'y2',
                hidden:false,
            },
            {
                cubicInterpolationMode: 'monotone',
                fill: true,
                label: titulo2,
                data: dados2,
                borderColor: borderColor3,
                backgroundColor: backgroundColor3,
                borderWidth: fullScreen ? 2 : 1,
                pointBorderWidth: fullScreen ? 2 : 1.5,
                pointRadius: 0,
                yAxisID: 'y2',
                hidden:false,
            },
            {
                cubicInterpolationMode: 'monotone',
                fill: true,
                label: titulo3,
                data: dados3,
                borderColor: borderColor4,
                backgroundColor: backgroundColor4,
                borderWidth: fullScreen ? 2 : 1,
                pointBorderWidth: fullScreen ? 2 : 1.5,
                pointRadius: 0,
                yAxisID: 'y2',
                hidden:false,
            },
            {
              cubicInterpolationMode: 'monotone',
                fill: true,
                label: titulo4,
                data: dados4,
                borderColor: borderColor5,
                backgroundColor: backgroundColor5,
                borderWidth: fullScreen ? 2 : 1,
                pointBorderWidth: fullScreen ? 2 : 1.5,
                pointRadius: 0,
                yAxisID: 'y2',
                hidden:false,
            },
            {
              cubicInterpolationMode: 'monotone',  
              fill: true,
              label: titulo5,
              data: dados5,
              borderColor: borderColor6,
              backgroundColor: backgroundColor6,
              borderWidth: fullScreen ? 2 : 1,
              pointBorderWidth: fullScreen ? 2 : 1.5,
              pointRadius: 0,
              yAxisID: 'y2',
              hidden:false,
          },
          {
            cubicInterpolationMode: 'monotone',
              fill: true,
              label: titulo6,
              data: dados6,
              borderColor: borderColor7,
              backgroundColor: backgroundColor7,
              borderWidth: fullScreen ? 2 : 1,
              pointBorderWidth: fullScreen ? 2 : 1.5,
              pointRadius: 0,
              yAxisID: 'y2',  
              hidden:false,
          },
          {
            cubicInterpolationMode: 'monotone',
              fill: true,
              label: titulo7,
              data: dados7,
              borderColor: borderColor8,
              backgroundColor: backgroundColor8,
              borderWidth: fullScreen ? 2 : 1,
              pointBorderWidth: fullScreen ? 2 : 1.5,
              pointRadius: 0,
              yAxisID: 'y2',
              hidden:false,
          },
          {
            cubicInterpolationMode: 'monotone',
            fill: true,
            label: titulo8,
            data: dados8,
            borderColor: borderColor9,
            backgroundColor: backgroundColor9,
            borderWidth: fullScreen ? 2 : 1,
            pointBorderWidth: fullScreen ? 2 : 1.5,
            pointRadius: 0,
            yAxisID: 'y2',
            hidden:false,
          },
          {
            cubicInterpolationMode: 'monotone',
              fill: true,
              label: titulo9,
              data: dados9,
              borderColor: borderColor10,
              backgroundColor: backgroundColor10,
              borderWidth: fullScreen ? 2 : 1,
              pointBorderWidth: fullScreen ? 2 : 1.5,
              pointRadius: 0,
              yAxisID: 'y2',
              hidden:false,
          },
          {
            cubicInterpolationMode: 'monotone',
              fill: true,
              label: titulo10,
              data: dados10,
              borderColor: borderColor11,
              backgroundColor: backgroundColor11,
              borderWidth: fullScreen ? 2 : 1,
              pointBorderWidth: fullScreen ? 2 : 1.5,
              pointRadius: 0,
              yAxisID: 'y2',
              hidden:false,
          },
          {
            cubicInterpolationMode: 'monotone',
              fill: true,
              label: titulo11,
              data: dados11,
              borderColor: borderColor12,
              backgroundColor: backgroundColor12,
              borderWidth: fullScreen ? 2 : 1,
              pointBorderWidth: fullScreen ? 2 : 1.5,
              pointRadius: 0,
              yAxisID: 'y2',
              hidden:false,
          }, 
        ],

    };
  }

  const corAtendimentosGripais = useMemo(() => {
    return '#2fb4ef';
  }, []);   

  const corBackgroundAtendimentosGripais = useMemo(() => {
    return 'rgba(47, 180, 239, 0.5)';
  }, []); 

  const borderColor2 = useMemo(() => {
    return "#E6194B"; // Vermelho
}, []);

const backgroundColor2 = useMemo(() => { 
    return "rgba(230, 25, 75, 0.5)";
}, []);

const borderColor3 = useMemo(() => {
    return "#3CB44B"; // Verde
}, []);

const backgroundColor3 = useMemo(() => { 
    return "rgba(60, 180, 75, 0.5)";
}, []);

const borderColor4 = useMemo(() => {
    return "#FFE119"; // Amarelo
}, []);

const backgroundColor4 = useMemo(() => { 
    return "rgba(255, 225, 25, 0.5)";
}, []);

const borderColor5 = useMemo(() => {
    return "#4363D8"; // Azul
}, []);

const backgroundColor5 = useMemo(() => { 
    return "rgba(70, 130, 180, 0.5)";
}, []);

const borderColor6 = useMemo(() => {
    return "#F58231"; // Laranja
}, []);

const backgroundColor6 = useMemo(() => { 
    return "rgba(245, 130, 48, 0.5)";
}, []);

const borderColor7 = useMemo(() => {
    return "#911EB4"; // Roxo
}, []);

const backgroundColor7 = useMemo(() => { 
    return "rgba(145, 30, 180, 0.5)";
}, []);

const borderColor8 = useMemo(() => {
    return "#46F0F0"; // Ciano
}, []);

const backgroundColor8 = useMemo(() => { 
    return "rgba(70, 240, 240, 0.5)";
}, []);

const borderColor9 = useMemo(() => {
    return "#F032E6"; // Magenta
}, []);

const backgroundColor9 = useMemo(() => { 
    return "rgba(240, 50, 230, 0.5)";
}, []);

const borderColor10 = useMemo(() => {
    return "#800000"; // Marrom
}, []);

const backgroundColor10 = useMemo(() => { 
    return "rgba(128, 0, 0, 0.5)";
}, []);

const borderColor11 = useMemo(() => {
    return "#808000"; // Oliva
}, []);

const backgroundColor11 = useMemo(() => { 
    return "rgba(128, 128, 0, 0.5)";
}, []);

const borderColor12 = useMemo(() => {
    return "#808080"; // Cinza
}, []);

const backgroundColor12 = useMemo(() => { 
    return "rgba(128, 128, 128, 0.5)";
}, []);



  const [dadosAtendimentoCoriza, setDadosAtendimentoCoriza] = useState(
    getDadosGrafico(null, null, false, "Atendimentos Gripais", "Coriza")
  );

  const [dadosAtendimentoCovid, setDadosAtendimentoCovid] = useState(
    getDadosGrafico(null, null, false, "Atendimentos Gripais", "Covid")
  );

  const [dadosAtendimentoDiarreia, setDadosAtendimentoDiarreia] = useState(
    getDadosGrafico(null, null, false, "Atendimentos Gripais", "Diarreia")
  );

  const [dadosAtendimentoDorDeCabeca, setDadosAtendimentoDorDeCabeca] = useState(
    getDadosGrafico(null, null, false, "Atendimentos Gripais", "Dor de Cabeça")
  );

  const [dadosAtendimentoDorDeGarganta, setDadosAtendimentoDorDeGarganta] = useState(
    getDadosGrafico(null, null, false, "Atendimentos Gripais", "Dor de Garganta")
  );

  const [dadosAtendimentoFaltaDeAr, setDadosAtendimentoFaltaDeAr] = useState(
    getDadosGrafico(null, null, false, "Atendimentos Gripais", "Falta de Ar")
  );

  const [dadosAtendimentoFebre, setDadosAtendimentoFebre] = useState(
    getDadosGrafico(null, null, false, "Atendimentos Gripais", "Febre")
  );

  const [dadosAtendimentoGripe, setDadosAtendimentoGripe] = useState(
    getDadosGrafico(null, null, false, "Atendimentos Gripais", "Gripe")
  );

  const [dadosAtendimentoResfriado, setDadosAtendimentoResfriado] = useState(
    getDadosGrafico(null, null, false, "Atendimentos Gripais", "Resfriado")
  );

  const [dadosAtendimentoTosse, setDadosAtendimentoTosse] = useState(
    getDadosGrafico(null, null, false, "Atendimentos Gripais", "Tosse")
  );

  const [dadosAtendimentoVomito, setDadosAtendimentoVomito] = useState(
    getDadosGrafico(null, null, false, "Atendimentos Gripais", "Vomito")
  );

  const [dadosAtendimentoTodos, setDadosAtendimentoTodos] = useState(
    getDadosGraficoTodos("Atendimentos Gripais", null, "Coriza", null, "Covid", null, "Diarreia", null, "Dor de Cabeça", null, "Dor de Garganta", null, "Falta de Ar", null, "Febre", null, "Gripe", null, "Resfriado", null, "Tosse", null, "Vomito", null, false)
  );

  const [dadosAtendimentoCoriza2023, setDadosAtendimentoCoriza2023] = useState(
    getDadosGrafico(null, null, false, "Atendimentos Gripais", "Coriza")
  );

  const [dadosAtendimentoCovid2023, setDadosAtendimentoCovid2023] = useState(
    getDadosGrafico(null, null, false, "Atendimentos Gripais", "Covid")
  );

  const [dadosAtendimentoDiarreia2023, setDadosAtendimentoDiarreia2023] = useState(
    getDadosGrafico(null, null, false, "Atendimentos Gripais", "Diarreia")
  );

  const [dadosAtendimentoDorDeCabeca2023, setDadosAtendimentoDorDeCabeca2023] = useState(
    getDadosGrafico(null, null, false, "Atendimentos Gripais", "Dor de Cabeça")
  );

  const [dadosAtendimentoDorDeGarganta2023, setDadosAtendimentoDorDeGarganta2023] = useState(
    getDadosGrafico(null, null, false, "Atendimentos Gripais", "Dor de Garganta")
  );

  const [dadosAtendimentoFaltaDeAr2023, setDadosAtendimentoFaltaDeAr2023] = useState(
    getDadosGrafico(null, null, false, "Atendimentos Gripais", "Falta de Ar")
  );

  const [dadosAtendimentoFebre2023, setDadosAtendimentoFebre2023] = useState(
    getDadosGrafico(null, null, false, "Atendimentos Gripais", "Febre")
  );

  const [dadosAtendimentoGripe2023, setDadosAtendimentoGripe2023] = useState(
    getDadosGrafico(null, null, false, "Atendimentos Gripais", "Gripe")
  );

  const [dadosAtendimentoResfriado2023, setDadosAtendimentoResfriado2023] = useState(
    getDadosGrafico(null, null, false, "Atendimentos Gripais", "Resfriado")
  );

  const [dadosAtendimentoTosse2023, setDadosAtendimentoTosse2023] = useState(
    getDadosGrafico(null, null, false, "Atendimentos Gripais", "Tosse")
  );

  const [dadosAtendimentoVomito2023, setDadosAtendimentoVomito2023] = useState(
    getDadosGrafico(null, null, false, "Atendimentos Gripais", "Vomito")
  );

  const [dadosAtendimentoTodos2023, setDadosAtendimentoTodos2023] = useState(
    getDadosGraficoTodos("Atendimentos Gripais", null, "Coriza", null, "Covid", null, "Diarreia", null, "Dor de Cabeça", null, "Dor de Garganta", null, "Falta de Ar", null, "Febre", null, "Gripe", null, "Resfriado", null, "Tosse", null, "Vomito", null, false)
  );

  const [minDate, setMinDate] = useState(null);
  const [minDateRangeInicial, setMinDateRangeInicial] = useState(null);
  const [minDateRangeFinal, setMinDateRangeFinal] = useState(null);
  const [minIdxDate, setMinIdxDate] = useState(0);
  const [maxDate, setMaxDate] = useState(null);
  const [maxDateRangeInicial, setMaxDateRangeInicial] = useState(null);
  const [maxDateRangeFinal, setMaxDateRangeFinal] = useState(null);
  const [maxIdxDate, setMaxIdxDate] = useState(0);
  const [inicioSemanaSelecionada, setInicioSemanaSelecionada] = useState(null);
  const [fimSemanaSelecionada, setFimSemanaSelecionada] = useState(null);
  const [inicioSemanaSelecionadaRange, setInicioSemanaSelecionadaRange] = useState(null);
  const [fimSemanaSelecionadaRange, setFimSemanaSelecionadaRange] = useState(null);
  const [datas, setDatas] = useState([]);
  const [semanas, setSemanas] = useState([]);
  const [inicioSemanas, setInicioSemanas] = useState([]);
  const [fimSemanas, setFimSemanas] = useState([]);
  
  
  const dataInicial = useRef(new Date("2023-01-01"));

  const [valueRangeData, setValueRangeData] = useState([0,0]);
  
  function compararPorData(a, b) {
    const dataA = new Date(a.data);
    const dataB = new Date(b.data);
    return dataA - dataB;
  }
  /*
  A média da relação percentual (covid-gripe) é aproximadamente 21.35%
A média da relação percentual (gripe-febre) é aproximadamente 46.58%
A média da relação percentual (gripe-tosse) é aproximadamente 27.46%
A média da relação percentual (gripe-diarreia) é aproximadamente 19.66%
A média da relação percentual (gripe-dor_de_cabeca) é aproximadamente 13.46%
A média da relação percentual (gripe-coriza) é aproximadamente 6.76%
A média da relação percentual (gripe-dor_de_garganta) é aproximadamente 9.99%
A média da relação percentual (gripe-falta_de_ar) é aproximadamente 10.29%
A média da relação percentual (gripe-resfriado) é aproximadamente 11.25%
A média da relação percentual (gripe-vomito) é aproximadamente 4.91%
    */

  let media_gripe = {
    "febre": 0.4658,
    "tosse": 0.2746,
    "diarreia": 0.1966,
    "dor_de_cabeca": 0.1346,
    "resfriado": 0.1125,
    "falta_de_ar": 0.1029,
    "dor_de_garganta": 0.0999,
    "coriza": 0.0676,   
    "vomito":0.0491
  }
  
  let media_covid = {
    "gripe":0.2135,
    "febre": 0.4658*0.2135,
    "tosse": 0.2746*0.2135,
    "diarreia": 0.1966*0.2135,
    "dor_de_cabeca": 0.1346*0.2135,
    "resfriado": 0.1125*0.2135,
    "falta_de_ar": 0.1029*0.2135,
    "dor_de_garganta": 0.0999*0.2135,
    "coriza": 0.0676*0.2135,   
    "vomito":0.0491*0.2135
  }

  const atualizaRangeGraficos = (valor, tipo, validacao) => {
    if(validacao){
        if(tipo === "max"){
            if(parseInt(valor) <= (parseInt(minIdxDate) + 1)){

                return;
            }
        } else {
            if(parseInt(valor) >= (parseInt(maxIdxDate) - 1) ){
                valor = parseInt(maxIdxDate) - 2;
                //return;
            }
        } 
    }

    /*let maior = tipo === "max" ? parseInt(valor) : maxIdxDate;
    let menor = tipo === "min" ? parseInt(valor) : minIdxDate;

    var minPercent = getPercent(parseInt(menor));
    var maxPercent = getPercent(parseInt(maior));*/

    if (tipo === "max") {
        setMaxDate(datas[valor]);
        setFimSemanaSelecionada(fimSemanas[valor]);
        setMaxIdxDate(valor);
        setValueRangeData([minIdxDate,valor]);
        
        setInicioSemanaSelecionadaRange(semanas[minIdxDate]);
        setFimSemanaSelecionadaRange(semanas[valor]);

        setMinDateRangeInicial(inicioSemanas[minIdxDate]);
        setMinDateRangeFinal(fimSemanas[minIdxDate]);
        setMaxDateRangeInicial(inicioSemanas[valor]);
        setMaxDateRangeFinal(fimSemanas[valor]);

        /*setSizeRange({width: (maxPercent - minPercent) + "%", left: sizeRange.left});*/
        ////filtrarMunicipio(municipioSelecionado, false, props.data,"",datas[minIdxDate],datas[valor]);
        
    } else {
        setMinDate(datas[valor]);
        setMinDateRangeInicial(inicioSemanas[valor]);
        setMinDateRangeFinal(fimSemanas[valor]);
        setMaxDateRangeInicial(inicioSemanas[maxIdxDate]);
        setMaxDateRangeFinal(fimSemanas[maxIdxDate]);

        setInicioSemanaSelecionadaRange(semanas[valor]);
        setFimSemanaSelecionadaRange(semanas[maxIdxDate]);
        setMinIdxDate(valor);

        /*let idxDataInicio = getIdxDataInicio(datas);
        setMinDate(datas[idxDataInicio]);
        setMinIdxDate(idxDataInicio);*/
        
        setValueRangeData([valor,maxIdxDate]);

        /*
        if(maxPercent > 85)
            maxPercent = 85;*/

        /*setSizeRange({width: (maxPercent - minPercent) + "%", left: minPercent+ "%"});*/
        ////filtrarMunicipio(municipioSelecionado, false,props.data,"",datas[valor],datas[maxIdxDate]);
    }
  }

  const handleChangeRangeData = (event, newValue) => {
    if(newValue[0] !== minIdxDate){
        atualizaRangeGraficos(newValue[0], "min", true);
    } else if(newValue[1] !== maxIdxDate) {    
        atualizaRangeGraficos(newValue[1], "max", true);
    }
  };

  function calculo_sintomas(covid, gripe, febre, tosse, diarreia, dor_de_cabeca, resfriado, falta_de_ar, dor_de_garganta, 
    coriza, vomito, y2, y3, y4, y5, y6, y7, y8, y9, y10, y11) {
    let y2_ = 0
    let y3_ = 0
    let y4_ = 0
    let y5_ = 0
    let y6_ = 0
    let y7_ = 0
    let y8_ = 0 
    let y9_ = 0
    let y10_ = 0
    let y11_ = 0

    if (covid) {
      if (gripe)
        y2_ = y2 * media_covid['gripe']
      if (febre)
        y3_ = y3 * media_covid['febre']
      if (tosse)
        y4_ = y4 * media_covid['tosse']
      if (diarreia)
        y5_ = y5 * media_covid['diarreia']
      if (dor_de_cabeca)
        y6_ = y6 * media_covid['dor_de_cabeca']
      if (resfriado)
        y7_ = y7 * media_covid['resfriado']
      if (falta_de_ar)
        y8_ = y8 * media_covid['falta_de_ar']
      if (dor_de_garganta)
        y9_ = y9 * media_covid['dor_de_garganta']
      if (coriza)
        y10_ = y10 * media_covid['coriza']
      if (vomito)
        y11_ = y11 * media_covid['vomito']
    }
        
    else if (gripe) {
      y2_ = y2
      if (febre)
        y3_ = y3 * media_gripe['febre']
      if (tosse)
        y4_ = y4 * media_gripe['tosse']
      if (diarreia)
        y5_ = y5 * media_gripe['diarreia']
      if (dor_de_cabeca)
        y6_ = y6 * media_gripe['dor_de_cabeca']
      if (resfriado)
        y7_ = y7 * media_gripe['resfriado']
      if (falta_de_ar)
        y8_ = y8 * media_gripe['falta_de_ar']
      if (dor_de_garganta)
        y9_ = y9 * media_gripe['dor_de_garganta']
      if (coriza)
        y10_ = y10 * media_gripe['coriza']
      if (vomito)
        y11_ = y11 * media_gripe['vomito']
    }

    else if (febre) {
      y2_ = y2
      y3_ = y3
      if (tosse)
        y4_ = y4 * (media_gripe['tosse'] / media_gripe['febre'])
      if (diarreia)
        y5_ = y5 * (media_gripe['diarreia'] / media_gripe['febre'])
      if (dor_de_cabeca)
        y6_ = y6 * (media_gripe['dor_de_cabeca'] / media_gripe['febre'])
      if (resfriado)
        y7_ = y7 * (media_gripe['resfriado'] / media_gripe['febre'])
      if (falta_de_ar)
        y8_ = y8 * (media_gripe['falta_de_ar'] / media_gripe['febre'])
      if (dor_de_garganta)
        y9_ = y9 * (media_gripe['dor_de_garganta'] / media_gripe['febre'])
      if (coriza)
        y10_ = y10 * (media_gripe['coriza'] / media_gripe['febre'])
      if (vomito)
        y11_ = y11 * (media_gripe['vomito'] / media_gripe['febre'])
    }
    else if (tosse)
    {
      y2_ = y2
      y3_ = y3
      y4_ = y4
      if  (diarreia)
        y5_ = y5 * (media_gripe['diarreia'] / media_gripe['tosse'])
      if  (dor_de_cabeca)
        y6_ = y6 * (media_gripe['dor_de_cabeca'] / media_gripe['tosse'])
      if  (resfriado)
        y7_ = y7 * (media_gripe['resfriado'] / media_gripe['tosse'])
      if  (falta_de_ar)
        y8_ = y8 * (media_gripe['falta_de_ar'] / media_gripe['tosse'])
      if  (dor_de_garganta)
        y9_ = y9 * (media_gripe['dor_de_garganta'] / media_gripe['tosse'])
      if  (coriza)
        y10_ = y10 * (media_gripe['coriza'] / media_gripe['tosse'])
      if  (vomito)
        y11_ = y11 * (media_gripe['vomito'] / media_gripe['tosse'])
    }
    else if (diarreia)
    {
      y2_ = y2
      y3_ = y3
      y4_ = y4
      y5_ = y5
      if (dor_de_cabeca)
        y6_ = y6 * (media_gripe['dor_de_cabeca'] / media_gripe['diarreia'])
      if (resfriado)
        y7_ = y7 * (media_gripe['resfriado'] / media_gripe['diarreia'])
      if (falta_de_ar)
        y8_ = y8 * (media_gripe['falta_de_ar'] / media_gripe['diarreia'])
      if (dor_de_garganta)
        y9_ = y9 * (media_gripe['dor_de_garganta'] / media_gripe['diarreia'])
      if (coriza)
        y10_ = y10 * (media_gripe['coriza'] / media_gripe['diarreia'])
      if (vomito)
        y11_ = y11 * (media_gripe['vomito'] / media_gripe['diarreia'])
    }

    else if (dor_de_cabeca)
    {
      y2_ = y2
      y3_ = y3
      y4_ = y4
      y5_ = y5
      y6_ = y6
      if (resfriado)
        y7_ = y7 * (media_gripe['resfriado'] / media_gripe['dor_de_cabeca'])
      if (falta_de_ar)
        y8_ = y8 * (media_gripe['falta_de_ar'] / media_gripe['dor_de_cabeca'])
      if (dor_de_garganta)
        y9_ = y9 * (media_gripe['dor_de_garganta'] / media_gripe['dor_de_cabeca'])
      if (coriza)
        y10_ = y10 * (media_gripe['coriza'] / media_gripe['dor_de_cabeca'])
      if (vomito)
        y11_ = y11 * (media_gripe['vomito'] / media_gripe['dor_de_cabeca'])
    }

    else if (resfriado)
    {
      y2_ = y2
      y3_ = y3
      y4_ = y4
      y5_ = y5
      y6_ = y6
      y7_ = y7
      if (falta_de_ar)
        y8_ = y8 * (media_gripe['falta_de_ar'] / media_gripe['resfriado'])
      if (dor_de_garganta)
        y9_ = y9 * (media_gripe['dor_de_garganta'] / media_gripe['resfriado'])
      if (coriza)
        y10_ = y10 * (media_gripe['coriza'] / media_gripe['resfriado'])
      if (vomito)
        y11_ = y11 * (media_gripe['vomito'] / media_gripe['resfriado'])
    }
    
    else if (falta_de_ar)
    {
      y2_ = y2
      y3_ = y3
      y4_ = y4
      y5_ = y5
      y6_ = y6
      y7_ = y7
      y8_ = y8
      if (dor_de_garganta)
        y9_ = y9 * (media_gripe['dor_de_garganta'] / media_gripe['falta_de_ar'])
      if (coriza)
        y10_ = y10 * (media_gripe['coriza'] / media_gripe['falta_de_ar'])
      if (vomito)
        y11_ = y11 * (media_gripe['vomito'] / media_gripe['falta_de_ar'])
    }

    else if (dor_de_garganta)
    {
      y2_ = y2
      y3_ = y3
      y4_ = y4
      y5_ = y5
      y6_ = y6
      y7_ = y7
      y8_ = y8
      y9_ = y9
      if (coriza)
        y10_ = y10 * (media_gripe['coriza'] / media_gripe['dor_de_garganta'])
      if (vomito)
        y11_ = y11 * (media_gripe['vomito'] / media_gripe['dor_de_garganta'])
    }
    
    else if (coriza)
    {
      y2_ = y2
      y3_ = y3
      y4_ = y4
      y5_ = y5
      y6_ = y6
      y7_ = y7
      y8_ = y8
      y9_ = y9
      y10_ = y10
      if (vomito)
        y11_ = y11 * (media_gripe['vomito'] / media_gripe['coriza'])
    }
    
    else if (vomito)
    {
      y2_ = y2
      y3_ = y3
      y4_ = y4
      y5_ = y5
      y6_ = y6
      y7_ = y7
      y8_ = y8
      y9_ = y9
      y10_ = y10
      y11_ = y11
    }
   
    else {
      y2_ = y2
      y3_ = y3
      y4_ = y4
      y5_ = y5
      y6_ = y6
      y7_ = y7
      y8_ = y8
      y9_ = y9
      y10_ = y10
      y11_ = y11
    }

    return {y2_, y3_, y4_, y5_, y6_, y7_, y8_, y9_, y10_, y11_}
  }

  useEffect(() => {
    console.log(ocultaCovid, ocultaGripe, ocultaFebre, ocultaTosse, 
      ocultaDiarreia, ocultaDorDeCabeca, ocultaResfriado, ocultaFaltaDeAr, ocultaDorDeGarganta, ocultaCoriza, ocultaVomito);

     atualizaGrafico(ocultaCovid, ocultaGripe, ocultaFebre, ocultaTosse, 
      ocultaDiarreia, ocultaDorDeCabeca, ocultaResfriado, ocultaFaltaDeAr, ocultaDorDeGarganta, ocultaCoriza, ocultaVomito);
  }, [minIdxDate, maxIdxDate, ocultaCovid, ocultaGripe, ocultaFebre, ocultaTosse, 
    ocultaDiarreia, ocultaDorDeCabeca, ocultaResfriado, ocultaFaltaDeAr, ocultaDorDeGarganta, ocultaCoriza, ocultaVomito]);

  useEffect(() => {
      let co_ibge = "3304557";
      let tempDatas = [];

      fetch('Dados/google_trends/municipios/dados_' + co_ibge + '.json'
                , {
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json'
                    }
                }
            )
                .then(function (response) {
                    //Limpa pontos anteriores
                    return response.json();
                })
                .then(function (myJson) {
                  dadosCoriza.current = [];
                  dadosCovid.current = [];
                  dadosDiarreia.current = [];
                  dadosDorDeCabeca.current = [];
                  dadosDorDeGarganta.current = [];
                  dadosFaltaDeAr.current = [];
                  dadosFebre.current = [];
                  dadosGripe.current = [];
                  dadosResfriado.current = [];
                  dadosTosse.current = [];
                  dadosVomito.current = [];
                  dadosGripal.current = [];
            
                  dadosCoriza2023.current = [];
                  dadosCovid2023.current = [];
                  dadosDiarreia2023.current = [];
                  dadosDorDeCabeca2023.current = [];
                  dadosDorDeGarganta2023.current = [];
                  dadosFaltaDeAr2023.current = [];
                  dadosFebre2023.current = [];
                  dadosGripe2023.current = [];
                  dadosResfriado2023.current = [];
                  dadosTosse2023.current = [];
                  dadosVomito2023.current = [];
                  dadosGripal2023.current = [];
                  
                  
                    myJson.atendimentos.sort(compararPorData);

                    for(let i = 0; i < myJson["atendimentos"].length; i++){
                      const element = myJson["atendimentos"][i];

                      element["data"] = element["data"];
                      tempDatas.push(element["data"]);

                      //console.log(element["data"]);

                      let is2023 = false;
                      if(element["data"] >= "2023-01-01"){
                        is2023 = true;
                      }

                      var obj = {};
                      obj['data'] = element["data"];
                      obj['total'] = element["t_g_coriza"];
                      dadosCoriza.current.push(obj);
                      if(is2023){
                        dadosCoriza2023.current.push(obj);
                      }

                      var obj2 = {};
                      obj2['data'] = element["data"];
                      obj2['total'] = element["t_g_covid"];
                      dadosCovid.current.push(obj2);
                      if(is2023){
                        dadosCovid2023.current.push(obj2);
                      }

                      var obj3 = {};
                      obj3['data'] = element["data"];
                      obj3['total'] = element["t_g_diarreia"];
                      dadosDiarreia.current.push(obj3);
                      if(is2023){
                        dadosDiarreia2023.current.push(obj3);
                      }

                      var obj4 = {};
                      obj4['data'] = element["data"];
                      obj4['total'] = element["t_g_dor_de_cabeca"];
                      dadosDorDeCabeca.current.push(obj4);
                      if(is2023){
                        dadosDorDeCabeca2023.current.push(obj4);
                      }

                      var obj5 = {};
                      obj5['data'] = element["data"];
                      obj5['total'] = element["t_g_dor_de_garganta"];
                      dadosDorDeGarganta.current.push(obj5);
                      if(is2023){
                        dadosDorDeGarganta2023.current.push(obj5);
                      }

                      var obj6 = {};
                      obj6['data'] = element["data"];
                      obj6['total'] = element["t_g_falta_de_ar"];
                      dadosFaltaDeAr.current.push(obj6);
                      if(is2023){
                        dadosFaltaDeAr2023.current.push(obj6);
                      }

                      var obj7 = {};
                      obj7['data'] = element["data"];
                      obj7['total'] = element["t_g_febre"];
                      dadosFebre.current.push(obj7);
                      if(is2023){
                        dadosFebre2023.current.push(obj7);
                      }

                      var obj8 = {};
                      obj8['data'] = element["data"];
                      obj8['total'] = element["t_g_gripe"];
                      dadosGripe.current.push(obj8);
                      if(is2023){
                        dadosGripe2023.current.push(obj8);
                      }

                      var obj9 = {};
                      obj9['data'] = element["data"];
                      obj9['total'] = element["t_g_resfriado"];
                      dadosResfriado.current.push(obj9);
                      if(is2023){
                        dadosResfriado2023.current.push(obj9);
                      }

                      var obj10 = {};
                      obj10['data'] = element["data"];
                      obj10['total'] = element["t_g_tosse"];
                      dadosTosse.current.push(obj10);
                      if(is2023){
                        dadosTosse2023.current.push(obj10);
                      }

                      var obj11 = {};
                      obj11['data'] = element["data"];
                      obj11['total'] = element["t_g_vomito"];
                      dadosVomito.current.push(obj11);
                      if(is2023){
                        dadosVomito2023.current.push(obj11);
                      }

                      var obj12 = {};
                      obj12['data'] = element["data"];
                      obj12['total'] = element["total_gripal"];
                      dadosGripal.current.push(obj12);
                      if(is2023){
                        dadosGripal2023.current.push(obj12);
                      } 
                    }

                    setDatas(tempDatas);

                    //converte datas para semanas
                    let tempSemanas = [];
                    for (let index = 0; index < tempDatas.length; index++) {
                      const element = tempDatas[index];
                      tempSemanas.push(moment(element).isoWeek());
                    }
                    setSemanas(tempSemanas);

                    let tempInicioSemana = [];
                    let tempFimSemana = [];
                    for (let index = 0; index < tempDatas.length; index++) {
                      const element = tempDatas[index];
                      tempInicioSemana.push(moment(element).startOf('isoWeek').format('YYYY-MM-DD'));
                      tempFimSemana.push(moment(element).endOf('isoWeek').format('YYYY-MM-DD'));
                    }

                    setInicioSemanas(tempInicioSemana);
                    setFimSemanas(tempFimSemana);

                    dadosCoriza.current = Object.fromEntries(
                      dadosCoriza.current.map(({data, total}) => [data, total])
                    );

                    dadosCovid.current = Object.fromEntries(
                      dadosCovid.current.map(({data, total}) => [data, total])
                    );

                    dadosDiarreia.current = Object.fromEntries(
                      dadosDiarreia.current.map(({data, total}) => [data, total])
                    );

                    dadosDorDeCabeca.current = Object.fromEntries(
                      dadosDorDeCabeca.current.map(({data, total}) => [data, total])
                    );

                    dadosDorDeGarganta.current = Object.fromEntries(
                      dadosDorDeGarganta.current.map(({data, total}) => [data, total])
                    );

                    dadosFaltaDeAr.current = Object.fromEntries(
                      dadosFaltaDeAr.current.map(({data, total}) => [data, total])
                    );

                    dadosFebre.current = Object.fromEntries(
                      dadosFebre.current.map(({data, total}) => [data, total])
                    );

                    dadosGripe.current = Object.fromEntries(
                      dadosGripe.current.map(({data, total}) => [data, total])
                    );

                    dadosResfriado.current = Object.fromEntries(
                      dadosResfriado.current.map(({data, total}) => [data, total])
                    );

                    dadosTosse.current = Object.fromEntries(
                      dadosTosse.current.map(({data, total}) => [data, total])
                    );

                    dadosVomito.current = Object.fromEntries(
                      dadosVomito.current.map(({data, total}) => [data, total])
                    );

                    dadosGripal.current = Object.fromEntries(
                      dadosGripal.current.map(({data, total}) => [data, total])
                    );

                    dadosCoriza2023.current = Object.fromEntries(
                      dadosCoriza2023.current.map(({data, total}) => [data, total])
                    );

                    dadosCovid2023.current = Object.fromEntries(
                      dadosCovid2023.current.map(({data, total}) => [data, total])
                    );

                    dadosDiarreia2023.current = Object.fromEntries(
                      dadosDiarreia2023.current.map(({data, total}) => [data, total])
                    );

                    dadosDorDeCabeca2023.current = Object.fromEntries(
                      dadosDorDeCabeca2023.current.map(({data, total}) => [data, total])
                    );

                    dadosDorDeGarganta2023.current = Object.fromEntries(
                      dadosDorDeGarganta2023.current.map(({data, total}) => [data, total])
                    );

                    dadosFaltaDeAr2023.current = Object.fromEntries(
                      dadosFaltaDeAr2023.current.map(({data, total}) => [data, total])
                    );

                    dadosFebre2023.current = Object.fromEntries(
                      dadosFebre2023.current.map(({data, total}) => [data, total])
                    );

                    dadosGripe2023.current = Object.fromEntries(
                      dadosGripe2023.current.map(({data, total}) => [data, total])
                    );

                    dadosResfriado2023.current = Object.fromEntries(
                      dadosResfriado2023.current.map(({data, total}) => [data, total])
                    );

                    dadosTosse2023.current = Object.fromEntries(
                      dadosTosse2023.current.map(({data, total}) => [data, total])
                    );

                    dadosVomito2023.current = Object.fromEntries(
                      dadosVomito2023.current.map(({data, total}) => [data, total])
                    );

                    dadosGripal2023.current = Object.fromEntries(
                      dadosGripal2023.current.map(({data, total}) => [data, total])
                    );                    

                    //setDadosAtendimentoTodos(getDadosGraficoTodos("Atendimentos Gripais", dadosGripal.current, "Coriza", dadosCoriza.current, "Covid", dadosCovid.current, "Diarreia", dadosDiarreia.current, "Dor de Cabeça", dadosDorDeCabeca.current, "Dor de Garganta", dadosDorDeGarganta.current, "Falta de Ar", dadosFaltaDeAr.current, "Febre", dadosFebre.current, "Gripe", dadosGripe.current, "Resfriado", dadosResfriado.current, "Tosse", dadosTosse.current, "Vomito", dadosVomito.current, false));
              
                    //setDadosAtendimentoTodos2023(getDadosGraficoTodos("Atendimentos Gripais", dadosGripal2023.current, "Coriza", dadosCoriza2023.current, "Covid", dadosCovid2023.current, "Diarreia", dadosDiarreia2023.current, "Dor de Cabeça", dadosDorDeCabeca2023.current, "Dor de Garganta", dadosDorDeGarganta2023.current, "Falta de Ar", dadosFaltaDeAr2023.current, "Febre", dadosFebre2023.current, "Gripe", dadosGripe2023.current, "Resfriado", dadosResfriado2023.current, "Tosse", dadosTosse2023.current, "Vomito", dadosVomito2023.current, false));

                    setIniciou(true);
              
                  });
  }, []);

  const [iniciou, setIniciou] = useState(false);

  const comparaDataInicio = (data1, data2) => {        
    if(data1 === null || data2 === null)
        return false;
    return data1 >= data2;
  }

  const getIdxDataInicio = (datas_local) => {
    var idx = 0;
    for (let idx_data = 0; idx_data < datas_local.length; idx_data++) {
        if (comparaDataInicio(new Date(datas_local[idx_data]), dataInicial.current)) {
            idx = idx_data;
            break;
        }
    }
    return idx;
  }

  const getLastYear = (dt) => {
    let dt_local = new Date(dt);
    dt_local.setFullYear(dt_local.getFullYear() - Number(1));
    return dt_local;
}

const calculateWeekStartEnd = (week, year) => {
  const firstDayOfYear = new Date(year, 0, 1);
  const firstWeekStart = new Date(firstDayOfYear);
  firstWeekStart.setDate(firstDayOfYear.getDate() - firstDayOfYear.getDay() + 1);
  const weekStartDate = new Date(firstWeekStart);
  weekStartDate.setDate(firstWeekStart.getDate() + (week - 1) * 7);
  const weekEndDate = new Date(weekStartDate);
  weekEndDate.setDate(weekStartDate.getDate() + 6);

  return [weekStartDate.toLocaleDateString(), weekEndDate.toLocaleDateString()];
};

  useEffect(() => {
    if(semanas.length > 0){
      let dtInicial = datas[datas.length -1] ;
      dataInicial.current = getLastYear(dtInicial);

      let idxDataInicio = getIdxDataInicio(datas);
      let idxDataSelecionada = datas.length - 1;
      
      setMaxDateRangeInicial(inicioSemanas[idxDataSelecionada]);
      setMaxDateRangeFinal(fimSemanas[idxDataSelecionada]);
      
      setInicioSemanaSelecionada(inicioSemanas[idxDataSelecionada]);
      setFimSemanaSelecionada(fimSemanas[idxDataSelecionada]);
      setInicioSemanaSelecionadaRange(semanas[idxDataInicio]);
      setFimSemanaSelecionadaRange(semanas[idxDataSelecionada]);

      atualizaGrafico(ocultaCovid, ocultaGripe, ocultaFebre, ocultaTosse, 
        ocultaDiarreia, ocultaDorDeCabeca, ocultaResfriado, ocultaFaltaDeAr, ocultaDorDeGarganta, ocultaCoriza, ocultaVomito);

    }
  }, [semanas]);

  useEffect(() => {
    if(iniciou){

      let dtInicial = datas[datas.length -1] ;
      dataInicial.current = getLastYear(dtInicial);

      let idxDataInicio = getIdxDataInicio(datas);
      let idxDataSelecionada = datas.length - 1;

      setMinDate(datas[idxDataInicio]);
      setMinDateRangeInicial(inicioSemanas[idxDataInicio]);
      setMinDateRangeFinal(fimSemanas[idxDataInicio]);
      setMinIdxDate(idxDataInicio);
      setMaxDate(datas[idxDataSelecionada]);
      setMaxIdxDate(idxDataSelecionada);
      
      setMaxDateRangeInicial(inicioSemanas[idxDataSelecionada]);
      setMaxDateRangeFinal(fimSemanas[idxDataSelecionada]);
      /*atualizaRangeGraficos(idxDataInicio, "min", false);
      atualizaRangeGraficos(idxDataSelecionada, "max", false);*/
      setValueRangeData([idxDataInicio,idxDataSelecionada]);
      //setSemanaSelecionada(semanas[idxDataSelecionada]);
      setInicioSemanaSelecionada(inicioSemanas[idxDataSelecionada]);
      setFimSemanaSelecionada(fimSemanas[idxDataSelecionada]);
      setInicioSemanaSelecionadaRange(semanas[idxDataInicio]);
      setFimSemanaSelecionadaRange(semanas[idxDataSelecionada]);
    }
  }, [iniciou]);

  var corLinhaMarcaoGrafico = ("rgba(94, 94, 94, 1)");

  function FormatarDoisDigitos(numero) {
    if(numero === null || numero === undefined)
        return "00";
    return numero.toString().padStart(2, '0');
  }

  function exibeMesAnoGrafico(index, values){

    const totalTicks = values.length;

    const date = moment(values[index].value);
    
    // Sempre mostre o primeiro e o último tick
    /*if (index === 0 || index === totalTicks - 1) {
        return `${FormatarDoisDigitos(date.isoWeek())}-${date.year()}`;
    }*/

    // Lógica para mostrar ticks intermediários
    let intermediateTicks = 10; // número de ticks intermediários desejados

    let totalWeekTicks = Math.floor(totalTicks / 7);

    if(totalWeekTicks < intermediateTicks)
        intermediateTicks = totalWeekTicks - 2;
        if(intermediateTicks < 0)
            intermediateTicks = 0;

    const stepSize = Math.floor(totalTicks / (intermediateTicks + 1));

    if (index % stepSize === 0) {
        return `${FormatarDoisDigitos(date.isoWeek())}-${date.year()}`;
    }

    return null; // Não mostra outros ticks
}

/*function triggerLegendClick(chart, datasetIndex) {
  console.log(chart);
  const legend = chart.current.legend;
  console.log(legend);

  const legendItem = legend.legendItems[datasetIndex];

  // Simula o evento de clique
  const clickEvent = new MouseEvent('click', {
      bubbles: true,
      cancelable: true,
      view: window
  });

  legendItem.$context = {
      chart: chart,
      dataIndex: datasetIndex,
      datasetIndex: datasetIndex,
      index: datasetIndex,
      element: null
  };

  legendItem.$handlers.onClick(clickEvent, legendItem);
}*/

  function atualizaGrafico(covid, gripe, febre, tosse, diarreia, dor_de_cabeca, resfriado, falta_de_ar, dor_de_garganta, coriza,
     vomito){
    /*let dadosGripalTemp = [];*/
          let dadosCorizaTemp = [];
          let dadosCovidTemp = [];
          let dadosDiarreiaTemp = [];
          let dadosDorDeCabecaTemp = [];
          let dadosDorDeGargantaTemp = [];
          let dadosFaltaDeArTemp = [];
          let dadosFebreTemp = [];
          let dadosGripeTemp = [];
          let dadosResfriadoTemp = [];
          let dadosTosseTemp = [];
          let dadosVomitoTemp = [];

          /*let dadosGripalTemp2023 = [];*/
          let dadosCorizaTemp2023 = [];
          let dadosCovidTemp2023 = [];
          let dadosDiarreiaTemp2023 = [];
          let dadosDorDeCabecaTemp2023 = [];
          let dadosDorDeGargantaTemp2023 = [];
          let dadosFaltaDeArTemp2023 = [];
          let dadosFebreTemp2023 = [];
          let dadosGripeTemp2023 = [];
          let dadosResfriadoTemp2023 = [];
          let dadosTosseTemp2023 = [];
          let dadosVomitoTemp2023 = [];

          let maiorValor = 0;


          Object.keys(dadosCoriza.current).forEach((key) => {
            console.log(key, datas[minIdxDate], datas[maxIdxDate], key >= datas[minIdxDate] && key <= datas[maxIdxDate]);
            if(key >= datas[minIdxDate] && key <= datas[maxIdxDate]){
            

              let resultado = calculo_sintomas(!covid, !gripe, 
                !febre, !tosse, !diarreia, 
                !dor_de_cabeca, !resfriado, !falta_de_ar, 
                !dor_de_garganta, !coriza, !vomito, 
                dadosGripe.current[key], dadosFebre.current[key], dadosTosse.current[key], dadosDiarreia.current[key], 
                dadosDorDeCabeca.current[key], dadosResfriado.current[key], dadosFaltaDeAr.current[key], 
                dadosDorDeGarganta.current[key], dadosCoriza.current[key], dadosVomito.current[key]);

              //console.log(key);

              const dataReferencia = moment('2023-01-01 00:00:00', 'YYYY-MM-DD HH:mm:ss');
              const dataConvertida = moment(key, 'YYYY-MM-DD HH:mm:ss');

              //console.log(dataConvertida, dataReferencia);

              let is2023 = false;
              if(dataConvertida.isAfter(dataReferencia)){
                is2023 = true;
              }

              var obj2 = {};
              obj2['data'] = key;
              obj2['total'] = resultado['y10_'] ? resultado['y10_'] : null;/*dadosCoriza.current[key];*/
              dadosCorizaTemp.push(obj2);

              if(is2023){
                dadosCorizaTemp2023.push(obj2);
              }

              var obj3 = {};
              obj3['data'] = key;
              obj3['total'] = dadosCovid.current[key];
              dadosCovidTemp.push(obj3);

              if(is2023){
                dadosCovidTemp2023.push(obj3);
              }

              var obj4 = {};
              obj4['data'] = key;
              obj4['total'] = resultado['y5_'] ? resultado['y5_'] : null; /*dadosDiarreia.current[key];*/
              dadosDiarreiaTemp.push(obj4);
              if(is2023){
                dadosDiarreiaTemp2023.push(obj4);
              }

              var obj5 = {};
              obj5['data'] = key;
              obj5['total'] = resultado['y6_'] ? resultado['y6_'] : null; /*dadosDorDeCabeca.current[key];*/
              dadosDorDeCabecaTemp.push(obj5);
              if(is2023){
                dadosDorDeCabecaTemp2023.push(obj5);
              }

              var obj6 = {};
              obj6['data'] = key;
              obj6['total'] = resultado['y9_'] ? resultado['y9_'] : null; /*dadosDorDeGarganta.current[key];*/
              dadosDorDeGargantaTemp.push(obj6);
              if(is2023){
                dadosDorDeGargantaTemp2023.push(obj6);
              }

              var obj7 = {};
              obj7['data'] = key;
              obj7['total'] = resultado['y8_'] ? resultado['y8_'] : null; /*dadosFaltaDeAr.current[key];*/
              dadosFaltaDeArTemp.push(obj7);
              if(is2023){
                dadosFaltaDeArTemp2023.push(obj7);
              }

              var obj8 = {};
              obj8['data'] = key;
              obj8['total'] = resultado['y3_'] ? resultado['y3_'] : null; /*dadosFebre.current[key];*/
              dadosFebreTemp.push(obj8);
              if(is2023){
                dadosFebreTemp2023.push(obj8);
              }

              var obj9 = {};
              obj9['data'] = key;
              obj9['total'] = resultado['y2_'] ? resultado['y2_'] : null; /*dadosGripe.current[key];*/
              dadosGripeTemp.push(obj9);
              if(is2023){
                dadosGripeTemp2023.push(obj9);
              }

              var obj10 = {};
              obj10['data'] = key;
              obj10['total'] = resultado['y7_'] ? resultado['y7_'] : null; /*dadosResfriado.current[key]*/;
              dadosResfriadoTemp.push(obj10);
              if(is2023){
                dadosResfriadoTemp2023.push(obj10);
              }

              var obj11 = {};
              obj11['data'] = key;
              obj11['total'] = resultado['y4_'] ? resultado['y4_'] : null;; /*dadosTosse.current[key];*/
              dadosTosseTemp.push(obj11);
              if(is2023){
                dadosTosseTemp2023.push(obj11);
              }

              var obj12 = {};
              obj12['data'] = key;
              obj12['total'] = resultado['y11_'] ? resultado['y11_'] : null; /*dadosVomito.current[key];*/
              dadosVomitoTemp.push(obj12);
              if(is2023){
                dadosVomitoTemp2023.push(obj12);
              }
            }
/*
            console.log(key);
            console.log(dadosCoriza.current[key]);*/
          });

          console.log(!covid, "preparando multiplicador");

          for (let key = 0; key < dadosCorizaTemp.length; key++) {
            if(!coriza && dadosCorizaTemp[key]["total"] > maiorValor)
              maiorValor = dadosCorizaTemp[key]["total"];
            if(!covid && dadosCovidTemp[key]["total"] > maiorValor)
              maiorValor = dadosCovidTemp[key]["total"];
            if(!diarreia && dadosDiarreiaTemp[key]["total"] > maiorValor)
              maiorValor = dadosDiarreiaTemp[key]["total"];
            if(!dor_de_cabeca && dadosDorDeCabecaTemp[key]["total"] > maiorValor)
              maiorValor = dadosDorDeCabecaTemp[key]["total"];
            if(!dor_de_garganta && dadosDorDeGargantaTemp[key]["total"] > maiorValor)
              maiorValor = dadosDorDeGargantaTemp[key]["total"];
            if(!falta_de_ar && dadosFaltaDeArTemp[key]["total"] > maiorValor)
              maiorValor = dadosFaltaDeArTemp[key]["total"];
            if(!febre && dadosFebreTemp[key]["total"] > maiorValor)
              maiorValor = dadosFebreTemp[key]["total"];
            if(!gripe && dadosGripeTemp[key]["total"] > maiorValor)
              maiorValor = dadosGripeTemp[key]["total"];
            if(!resfriado && dadosResfriadoTemp[key]["total"] > maiorValor)
              maiorValor = dadosResfriadoTemp[key]["total"];
            if(!tosse && dadosTosseTemp[key]["total"] > maiorValor)
              maiorValor = dadosTosseTemp[key]["total"];
            if(!vomito && dadosVomitoTemp[key]["total"] > maiorValor)
              maiorValor = dadosVomitoTemp[key]["total"];
            /*if(!coriza && dadosCorizaTemp[key]["total"] > maiorValor)
              maiorValor = dadosCorizaTemp[key]["total"]; */          
          };
          
          let multiplicador = maiorValor == 0 ? 1 : 100 / maiorValor;

          console.log(maiorValor, multiplicador);

          //aplique o multiplicador para todos os valores
          for (let key = 0; key < dadosCorizaTemp.length; key++) {
            dadosCorizaTemp[key]['total'] = dadosCorizaTemp[key]['total'] * multiplicador;
            dadosCovidTemp[key]['total'] = dadosCovidTemp[key]['total'] * multiplicador;
            dadosDiarreiaTemp[key]['total'] = dadosDiarreiaTemp[key]['total'] * multiplicador;
            dadosDorDeCabecaTemp[key]['total'] = dadosDorDeCabecaTemp[key]['total'] * multiplicador;
            dadosDorDeGargantaTemp[key]['total'] = dadosDorDeGargantaTemp[key]['total'] * multiplicador;
            dadosFaltaDeArTemp[key]['total'] = dadosFaltaDeArTemp[key]['total'] * multiplicador;
            dadosFebreTemp[key]['total'] = dadosFebreTemp[key]['total'] * multiplicador;
            dadosGripeTemp[key]['total'] = dadosGripeTemp[key]['total'] * multiplicador;
            dadosResfriadoTemp[key]['total'] = dadosResfriadoTemp[key]['total'] * multiplicador;
            dadosTosseTemp[key]['total'] = dadosTosseTemp[key]['total'] * multiplicador;
            dadosVomitoTemp[key]['total'] = dadosVomitoTemp[key]['total'] * multiplicador;
          };

          /*
          dadosGripalTemp = Object.fromEntries(
            dadosGripalTemp.map(({data, total}) => [data, total])
          ); */

          dadosCorizaTemp = Object.fromEntries(
            dadosCorizaTemp.map(({data, total}) => [data, total])
          );

          dadosCovidTemp = Object.fromEntries(
            dadosCovidTemp.map(({data, total}) => [data, total])
          );

          dadosDiarreiaTemp = Object.fromEntries(
            dadosDiarreiaTemp.map(({data, total}) => [data, total])
          );

          dadosDorDeCabecaTemp = Object.fromEntries(
            dadosDorDeCabecaTemp.map(({data, total}) => [data, total])
          );

          dadosDorDeGargantaTemp = Object.fromEntries(
            dadosDorDeGargantaTemp.map(({data, total}) => [data, total])
          );

          dadosFaltaDeArTemp = Object.fromEntries(
            dadosFaltaDeArTemp.map(({data, total}) => [data, total])
          );

          dadosFebreTemp = Object.fromEntries(
            dadosFebreTemp.map(({data, total}) => [data, total])
          );

          dadosGripeTemp = Object.fromEntries(
            dadosGripeTemp.map(({data, total}) => [data, total])
          );

          dadosResfriadoTemp = Object.fromEntries(
            dadosResfriadoTemp.map(({data, total}) => [data, total])
          );

          dadosTosseTemp = Object.fromEntries(
            dadosTosseTemp.map(({data, total}) => [data, total])
          );

          dadosVomitoTemp = Object.fromEntries(
            dadosVomitoTemp.map(({data, total}) => [data, total])
          );

/*
          dadosGripalTemp2023 = Object.fromEntries(
            dadosGripalTemp2023.map(({data, total}) => [data, total])
          );*/

          dadosCorizaTemp2023 = Object.fromEntries(
            dadosCorizaTemp2023.map(({data, total}) => [data, total])
          );

          dadosCovidTemp2023 = Object.fromEntries(
            dadosCovidTemp2023.map(({data, total}) => [data, total])
          );

          dadosDiarreiaTemp2023 = Object.fromEntries(
            dadosDiarreiaTemp2023.map(({data, total}) => [data, total])
          );

          dadosDorDeCabecaTemp2023 = Object.fromEntries(
            dadosDorDeCabecaTemp2023.map(({data, total}) => [data, total])
          );

          dadosDorDeGargantaTemp2023 = Object.fromEntries(
            dadosDorDeGargantaTemp2023.map(({data, total}) => [data, total])
          );

          dadosFaltaDeArTemp2023 = Object.fromEntries(
            dadosFaltaDeArTemp2023.map(({data, total}) => [data, total])
          );

          dadosFebreTemp2023 = Object.fromEntries(
            dadosFebreTemp2023.map(({data, total}) => [data, total])
          );

          dadosGripeTemp2023 = Object.fromEntries(
            dadosGripeTemp2023.map(({data, total}) => [data, total])
          );

          dadosResfriadoTemp2023 = Object.fromEntries(
            dadosResfriadoTemp2023.map(({data, total}) => [data, total])
          );

          dadosTosseTemp2023 = Object.fromEntries(
            dadosTosseTemp2023.map(({data, total}) => [data, total])
          );

          dadosVomitoTemp2023 = Object.fromEntries(
            dadosVomitoTemp2023.map(({data, total}) => [data, total])
          );


          setDadosAtendimentoTodos(getDadosGraficoTodos("Atendimentos Gripais", dadosGripal.current, "Coriza", dadosCorizaTemp, "Covid", dadosCovidTemp, "Diarreia", dadosDiarreiaTemp, "Dor de Cabeça", dadosDorDeCabecaTemp, "Dor de Garganta", dadosDorDeGargantaTemp, "Falta de Ar", dadosFaltaDeArTemp, "Febre", dadosFebreTemp, "Gripe", dadosGripeTemp, "Resfriado", dadosResfriadoTemp, "Tosse", dadosTosseTemp, "Vomito", dadosVomitoTemp, false));
              
          setDadosAtendimentoTodos2023(getDadosGraficoTodos("Atendimentos Gripais", dadosGripal2023.current, "Coriza", dadosCorizaTemp2023, "Covid", dadosCovidTemp2023, "Diarreia", dadosDiarreiaTemp2023, "Dor de Cabeça", dadosDorDeCabecaTemp2023, "Dor de Garganta", dadosDorDeGargantaTemp2023, "Falta de Ar", dadosFaltaDeArTemp2023, "Febre", dadosFebreTemp2023, "Gripe", dadosGripeTemp2023, "Resfriado", dadosResfriadoTemp2023, "Tosse", dadosTosseTemp2023, "Vomito", dadosVomitoTemp2023, false));
        }

  const options = {
    animation: false,
    responsive: true,
    plugins: {
      legend: {
        onClick: (e, legendItem, legend) => {
          const index = legendItem.datasetIndex;
          const ci = legend.chart;
          const meta = ci.getDatasetMeta(index);
          
          console.log(index, meta);
          // Alterna a visibilidade da variável
          meta.hidden = meta.hidden === null ? !ci.data.datasets[index].hidden : null;
          /*calculo_sintomas(ci.data.datasets[0].hidden, ci.data.datasets[1].hidden,
            ci.data.datasets[2].hidden, ci.data.datasets[3].hidden, ci.data.datasets[4].hidden,
            ci.data.datasets[5].hidden, ci.data.datasets[6].hidden, ci.data.datasets[7].hidden, ci.data.datasets[8].hidden,
            ci.data.datasets[9].hidden, ci.data.datasets[10].hidden parei aqui)*/
            // Atualiza o gráfico
          ci.update();

          let ocultaCovidTemp = ocultaCovid;
          let ocultaGripeTemp = ocultaGripe;
          let ocultaFebreTemp = ocultaFebre;
          let ocultaTosseTemp = ocultaTosse;
          let ocultaDiarreiaTemp = ocultaDiarreia;
          let ocultaDorDeCabecaTemp = ocultaDorDeCabeca;
          let ocultaResfriadoTemp = ocultaResfriado;
          let ocultaFaltaDeArTemp = ocultaFaltaDeAr;
          let ocultaDorDeGargantaTemp = ocultaDorDeGarganta;
          let ocultaCorizaTemp = ocultaCoriza;
          let ocultaVomitoTemp = ocultaVomito;

          switch (index) {
            case 2:{
              setOcultaCovid(meta.hidden ? true : false);
              ocultaCovidTemp = meta.hidden ? true : false;
              break;
            }
            case 8:{
              setOcultaGripe(meta.hidden ? true : false);
              ocultaGripeTemp = meta.hidden ? true : false;
              break;
            }
            case 7:{
              setOcultaFebre(meta.hidden ? true : false);
              ocultaFebreTemp = meta.hidden ? true : false;
              break;
            }
            case 10:{
              setOcultaTosse(meta.hidden ? true : false);
              ocultaTosseTemp = meta.hidden ? true : false;
              break;
            }
            case 3:{
              setOcultaDiarreia(meta.hidden ? true : false);
              ocultaDiarreiaTemp = meta.hidden ? true : false;
              break;
            }
            case 4:{
              setOcultaDorDeCabeca(meta.hidden ? true : false);
              ocultaDorDeCabecaTemp = meta.hidden ? true : false;
              break;
            }
            case 9:{
              setOcultaResfriado(meta.hidden ? true : false);
              ocultaResfriadoTemp = meta.hidden ? true : false;
              break;
            }
            case 6:{
              setOcultaFaltaDeAr(meta.hidden ? true : false);
              ocultaFaltaDeArTemp = meta.hidden ? true : false;
              break;
            }
            case 5:{
              setOcultaDorDeGarganta(meta.hidden ? true : false);
              ocultaDorDeGargantaTemp = meta.hidden ? true : false;
              break;
            }
            case 1:{
              setOcultaCoriza(meta.hidden ? true : false);
              ocultaCorizaTemp = meta.hidden ? true : false;
              break;
            }
            case 11:{
              setOcultaVomito(meta.hidden ? true : false);
              ocultaVomitoTemp = meta.hidden ? true : false;
              break;
            }
            default:
              break;
          }

          atualizaGrafico(ocultaCovidTemp, ocultaGripeTemp, ocultaFebreTemp, ocultaTosseTemp,
            ocultaDiarreiaTemp, ocultaDorDeCabecaTemp, ocultaResfriadoTemp, ocultaFaltaDeArTemp, ocultaDorDeGargantaTemp, ocultaCorizaTemp, ocultaVomitoTemp);
          /*setOcultaCovid(ci.data.datasets[0].hidden);
          setOcultaGripe(ci.data.datasets[1].hidden);
          setOcultaFebre(ci.data.datasets[2].hidden);
          setOcultaTosse(ci.data.datasets[3].hidden);
          setOcultaDiarreia(ci.data.datasets[4].hidden);
          setOcultaDorDeCabeca(ci.data.datasets[5].hidden);
          setOcultaResfriado(ci.data.datasets[6].hidden);
          setOcultaFaltaDeAr(ci.data.datasets[7].hidden);
          setOcultaDorDeGarganta(ci.data.datasets[8].hidden);
          setOcultaCoriza(ci.data.datasets[9].hidden);
          setOcultaVomito(ci.data.datasets[10].hidden);

          console.log("hidden", ci.data.datasets[0].hidden, ci.data.datasets[1].hidden,
            ci.data.datasets[2].hidden, ci.data.datasets[3].hidden, ci.data.datasets[4].hidden,
            ci.data.datasets[5].hidden, ci.data.datasets[6].hidden, ci.data.datasets[7].hidden, ci.data.datasets[8].hidden,
            ci.data.datasets[9].hidden, ci.data.datasets[10].hidden);

          atualizaGrafico(ci.data.datasets[0].hidden, ci.data.datasets[1].hidden,
            ci.data.datasets[2].hidden, ci.data.datasets[3].hidden, ci.data.datasets[4].hidden,
            ci.data.datasets[5].hidden, ci.data.datasets[6].hidden, ci.data.datasets[7].hidden, ci.data.datasets[8].hidden,
            ci.data.datasets[9].hidden, ci.data.datasets[10].hidden);*/
          
          /*for (let i = 0; i < dadosCoriza.current.length; i++) {
            console.log(dadosCoriza.current[i]);
            let retorno = calculo_sintomas(ci.data.datasets[0].hidden, ci.data.datasets[1].hidden,
              ci.data.datasets[2].hidden, ci.data.datasets[3].hidden, ci.data.datasets[4].hidden,
              ci.data.datasets[5].hidden, ci.data.datasets[6].hidden, ci.data.datasets[7].hidden, 
              ci.data.datasets[8].hidden, ci.data.datasets[9].hidden, ci.data.datasets[10].hidden, 
              dadosGripal.current[i], dadosCoriza.current[i], dadosCovid.current[i], dadosDiarreia.current[i], 
              dadosDorDeCabeca.current[i], dadosDorDeGarganta.current[i], dadosFaltaDeAr.current[i], 
              dadosFebre.current[i], dadosGripe.current[i], dadosResfriado.current[i], dadosTosse.current[i], 
              dadosVomito.current[i]);
          }*/
           
          //console.log(index)
        },
        position: 'bottom',
        align: 'start',
        labels: {
          boxWidth: 15,
          //color: theme === "dark" ? texto_grafico_dark : texto_grafico_light,
          font: { 
            size: 10
          }
        }
      },
      title: {
        display: false,
        text: 'Chart.js Line Chart',
      },
      /*annotation: {
        annotations: [
          {
            type: 'line',
            mode: 'vertical',
            scaleID: 'x',
            value: "2024-03-02",
            borderColor: corLinhaMarcaoGrafico,
            borderWidth: 1,
          },
        ],
      },*/
    },
    scales: {
      y:{
        type: 'linear',
        display: true,
        position: 'left',
        id: 'y-axis-1',
        labelString: 'Dataset 1',
        afterBuildTicks: function(scale) {
          scale.ticks.forEach(function(tick, index, ticks) {
            ticks[index].color = 'rgba(75,192,192,1)'; // Cor da escala do primeiro eixo y
          });
        },
        title: {
          display: true,
          text: 'Provável Sindrome Gripal na APS', // Texto do título do eixo Y
          //color: theme === "dark" ? "#FFFFFF" : "#000000",
          font: {
              size: 12,
              weight: 'bold'
          }
        }
      },
      y2:{
        type: 'linear',
          display: true,
          position: 'right',
          id: 'y-axis-2',
          labelString: 'Dataset 2',
          afterBuildTicks: function(scale) {
            scale.ticks.forEach(function(tick, index, ticks) {
              ticks[index].color = 'rgba(255, 99, 132, 1)'; // Cor da escala do segundo eixo y
            });
          },
          title: {
            display: true,
            text: 'Termos', // Texto do título do eixo Y
            //color: theme === "dark" ? "#FFFFFF" : "#000000",
            font: {
                size: 12,
                weight: 'bold'
            }
          }
      },
      /*x:{
        type: 'time',
        time: {
          parser: 'YYYY/MM/DD',
          unit: 'day',
          displayFormats: {
            'day': 'DD/MM/YYYY',
            'month' : 'DD/MM/YYYY'
          },
        },
        ticks:{
          callback: function(value, index, values) {
            // Converte a data em um objeto moment
           
            return exibeMesAnoGrafico(index, values);
        },
        }
      }*/
        x: {  
          type: 'time',
          time: {
            parser: 'YYYY/MM/DD',
            unit: 'day',
            displayFormats: {
              'day': 'DD/MM/YYYY',
              'month' : 'DD/MM/YYYY'
            },
          },
          ticks:{
            font: {
              size: 10, // 'size' now within object 'font {}'
            },
            //stepSize: 7,
            callback: function(value, index, values) {
                
                return exibeMesAnoGrafico(index, values);
                
            },
          },
          min: datas != null ? datas[minIdxDate] : null,
          max: datas != null ? datas[maxIdxDate] : null,
        }
    },
  };
  
  function extraiAno(data){
    if(data && data.length > 0)
        return data.substring(0,4);
    else 
        return "";
  }

  let mdbootstrap = 12;

  function mudarGrafico(index){
    //oculta dataset correspondente no gráfico
    return function(){
      const ci = chartRef.current.legend.chart;
      const meta = ci.getDatasetMeta(index);
      meta.hidden = meta.hidden === null ? !ci.data.datasets[index].hidden : null;
      ci.update();
    }
  };

  function getCidades(somenteNomes) {
    var nomes_municipios = [];

    if(somenteNomes){
        nomes_municipios.unshift(
            "todos"
        );
        nomes_municipios.sort((a, b) => a.localeCompare(b));
    } else {
        nomes_municipios.unshift({
            "cod_ibge": 0,
            "municipio": "todos"
        });
        nomes_municipios.sort((a, b) => a["municipio"].localeCompare(b["municipio"]));
    }

    return nomes_municipios;
}

  const [loading, setLoading] = useState(true);
  const [cod_ibge, setCod_Ibge] = useState("todos");
  const [municipioSelecionado, setMunicipioSelecionado] = useState("Todos");
  const [estadoSelecionado, setEstadoSelecionado] = useState("Todos");

  const [cidades_labels, setCidades_Labels] = useState([{cod_ibge: "todos", label: "Todos"}]);
  const [listaMunicipios, setListaMunicipios] = useState(getCidades());

  const defaultPropsMunicipio = {
      options: cidades_labels,
      getOptionLabel: (option) => option.label,
  };

  var estados_labels = [
    {label:"Todos"},
    {label:"Acre"},
    {label:"Alagoas"},
    {label:"Amapá"},
    {label:"Amazonas"},
    {label:"Bahia"},
    {label:"Ceará"},
    {label:"Distrito Federal"},
    {label:"Espírito Santo"},
    {label:"Goiás"},
    {label:"Maranhão"},
    {label:"Mato Grosso"},
    {label:"Mato Grosso do Sul"},
    {label:"Minas Gerais"},
    {label:"Pará"},
    {label:"Paraíba"},
    {label:"Paraná"},
    {label:"Pernambuco"},
    {label:"Piauí"},
    {label:"Rio de Janeiro"},
    {label:"Rio Grande do Norte"},
    {label:"Rio Grande do Sul"},
    {label:"Rondônia"},
    {label:"Roraima"},
    {label:"Santa Catarina"},        
    {label:"São Paulo"},
    {label:"Sergipe"},
    {label:"Tocantins"},
];

var defaultProps = {
    options: estados_labels,
    getOptionLabel: (option) => option.label,
};

const getNomeCidadeWhereCodIbge = (cod_ibge) => {
  for (let index = 0; index < cidades_labels.length; index++) {
    const element = cidades_labels[index];
    if(element["cod_ibge"] === cod_ibge){
        return element["label"];
    }
  }
  return "Todos";
};

const handleChangeMunicipioNome = (nome_municipio) => {
  console.log("handleChangeMunicipioNome: ");
  setCod_Ibge(nome_municipio);
  setMunicipioSelecionado(getNomeCidadeWhereCodIbge(nome_municipio));
};

useEffect(() => {
  
  if(estadoSelecionado){
    
    let siglaEstado = "";
    for (let index = 0; index < cod_estados.length; index++) {
      const element = cod_estados[index];
      //compare em minusculo
      if(element["NOME"].toLowerCase() === estadoSelecionado.toLowerCase()){
          siglaEstado = element["UF"];
          break;
      }
    }
    
    carregaMunicipiosPorEstado(siglaEstado);
  }
}, [estadoSelecionado, municipioSelecionado]);

function carregaMunicipiosPorEstado(siglaEstado){
  if(!loading){
      setLoading(true);
  }
  console.log("carregaMunicipiosPorEstado");
  if(siglaEstado !== ''){
      fetch('Dados/lista_municipios/lista_municipios_'+siglaEstado+'.json'
          , {
              headers: {
                  'Content-Type': 'application/json',
                  'Accept': 'application/json'
              }
          }
      )
      .then(function (response) {
          //Limpa pontos anteriores
          return response.json();
      })
      .then(function (myJson) {
          let listaTemp = [];
          let cidades_labels_tmp = [{cod_ibge: "todos", label: "Todos"}];
          for(let i = 0; i < myJson.length; i++){
              listaTemp.push({
                  "cod_ibge": myJson[i]["COD_IBGE"],
                  "municipio": myJson[i]["MUNICIPIO"],
                  "latitude": myJson[i]["Y"],
                  "longitude": myJson[i]["X"]
              });

              cidades_labels_tmp.push({cod_ibge: myJson[i]["COD_IBGE"],  label: myJson[i]["MUNICIPIO"]});
          }

          

          setCidades_Labels(cidades_labels_tmp);

          listaTemp.sort((a, b) => a["municipio"].localeCompare(b["municipio"]));
          
          listaTemp.unshift({
              "cod_ibge": 0,
              "municipio": "Todos"
          });

          setListaMunicipios(listaTemp); 

      });
  }
}

  //deixe o acordion aberto
  const [open, setOpen] = useState(true);

  return (
    <div>
      Conteúdo Acima
      <Accordion>
      <Accordion.Item eventKey="0">
        <Accordion.Header>Google Trends</Accordion.Header>
        <Accordion.Body>
            <Container>
                <div className={style.seleciona_periodo_semana}>
                    <div className='titulo_semana_ep'>
                        Semana: {FormatarDoisDigitos(inicioSemanaSelecionadaRange)+ "-" + extraiAno(minDateRangeFinal)}&nbsp; a &nbsp;{FormatarDoisDigitos(fimSemanaSelecionadaRange)+ "-" + extraiAno(maxDateRangeFinal)}
                    </div>
                </div>
                <div className={style.seleciona_periodo}>
                    <div className='dataSemanaRange'>
                        <Moment date={minDateRangeInicial} format='DD/MM/YYYY'></Moment>&nbsp; a &nbsp;<Moment date={minDateRangeFinal} format='DD/MM/YYYY'></Moment>
                    </div>
                    <Box sx={{ width: "220px", paddingTop:"0px", marginTop:"-5px" }}>
                        <Slider
                            getAriaLabel={() => 'Date range'}
                            value={valueRangeData}
                            onChange={handleChangeRangeData}
                            valueLabelDisplay="off"
                            min={0}
                            max={datas != null ? datas.length - 1 : 0}
                        />
                    </Box>
                    <div className='dataSemanaRange'>
                        <Moment date={maxDateRangeInicial} format='DD/MM/YYYY'></Moment>&nbsp; a &nbsp;<Moment date={maxDateRangeFinal} format='DD/MM/YYYY'></Moment>
                    </div>
                </div>
                {false && 
                <div className={style.seleciona_municipio}> 
                  <Autocomplete
                      {...defaultProps}
                      disableClearable
                      id="autocomplete_estado"
                      className='change_color_text'
                      isOptionEqualToValue={(option, value) => option.label === value.label}
                      value={{label:estadoSelecionado}}
                      style={{ width: 300 }}
                      onChange={(event, newValue) => {
                          setLoading(true);
                          setEstadoSelecionado(newValue.label);
                          setCod_Ibge("todos");
                          setMunicipioSelecionado("Todos");
                      }}
                      renderInput={(params) => (
                          <TextField className='autocomplete_estado_textfield' {...params} label={"Estado"} size="small"/>
                      )}
                  />
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  
                  <Autocomplete
                      {...defaultPropsMunicipio}
                      disableClearable
                      id="autocomplete_municipio"
                      className='change_color_text'
                      isOptionEqualToValue={(option, value) => option.cod_ibge === value.cod_ibge}
                      value={{cod_ibge:cod_ibge, label:municipioSelecionado}}
                      style={{ width: 300 }}
                      onChange={(event, newValue) => {
                          handleChangeMunicipioNome(newValue.cod_ibge);
                      }}
                      renderInput={(params) => (
                          <TextField {...params} label={"Cidade"} size="small"/>
                      )}
                  />
              </div>
              }
                {exibeBotoes &&
                <div>
                  <button onClick={mudarGrafico(1)}>Coriza</button>
                  <button onClick={mudarGrafico(2)}>Covid</button>
                  <button onClick={mudarGrafico(3)}>Diarreia</button>
                  <button onClick={mudarGrafico(4)}>Dor de Cabeça</button>
                  <button onClick={mudarGrafico(5)}>Dor de Garganta</button>
                  <button onClick={mudarGrafico(6)}>Falta de Ar</button>
                  <button onClick={mudarGrafico(7)}>Febre</button>
                  <button onClick={mudarGrafico(8)}>Gripe</button>
                  <button onClick={mudarGrafico(9)}>Resfriado</button>
                  <button onClick={mudarGrafico(10)}>Tosse</button>
                  <button onClick={mudarGrafico(11)}>Vomito</button>
                </div>}
              <br></br>
              <Row>
                <Col md={mdbootstrap}>
                  <Line ref={chartRef} data={dadosAtendimentoTodos} options={options} />
                </Col>
              </Row>
          
            </Container>
        </Accordion.Body>
      </Accordion.Item>
      </Accordion>
      Conteúdo Abaixo


    </div>
  );
}

export default App;
